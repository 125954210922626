import { useRef, useState, useEffect } from 'react'

import styled from 'styled-components'

import iconInfo from 'images/icons/info-black.svg'
import Button from 'components/Shared/Button'

const LabelWrap = styled.div`
  position: ${({ adaptiveSize }) => (adaptiveSize ? 'relative' : 'static')};
  width: ${({ adaptiveSize }) => (adaptiveSize ? '365px' : '100%')};
  height: ${({ adaptiveSize }) => (adaptiveSize ? '36px' : '100%')};
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ width }) => (width ? width : '100%')};

  ${({ styles }) => styles}
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;

  ${({ styles }) => styles}
`

const LabelText = styled.p`
  position: relative;
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 104px;
  z-index: 1;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  resize: none;

  padding: 8px;
  background: var(--white);

  border: 1px solid var(--bright);
  ${({ styles }) => styles}
`

const ImgInfo = styled.img`
  display: inline-block;
`

const ErrorMessage = styled.p`
  position: absolute;
  bottom: -17px;
  left: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--red);
`

const BlackLoupe = styled.div`
  display: block;

  .isBig > & {
    display: none;
  }
`
const WhiteLoupe = styled.div`
  display: none;

  .isBig > & {
    display: block;
  }
`

const Rubbish = styled.div`
  display: none;

  .addRubbish > & {
    display: block;
  }
`

const NotesArea = ({
  label,
  width,
  infoIcon,
  type,
  name,
  value,
  handleChange,
  placeholder,
  required,
  title,
  disabled,
  error,
  errorMessage = 'Invalid Value',
  props,
  textereaId,
  labelId,
  handleClear,
  adaptiveSize,

  labelStyles,
  textareaStyles,
  infoWrapStyles,

  newBase,
  errors,
  formData,
  defaultValue,
  isReset,
}) => {
  const currentValue =
    formData && formData[name] ? formData[name] : defaultValue || ''
  const [isValue, setIsValue] = useState(currentValue)
  const isInvalid = required && errors && errors[name] && !isValue

  useEffect(() => {
    setIsValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (isReset) setIsValue(currentValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset])

  const generateCSS = (elementStyles) => {
    if (!elementStyles) return

    const cssText = Object.entries(elementStyles)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ')

    return cssText
  }

  const labelCss = generateCSS(labelStyles)
  const textareaCss = generateCSS(textareaStyles)
  const infoWrapCss = generateCSS(infoWrapStyles)

  //------------------------ testTexterea
  const tx = document.querySelector('#testTexterea')
  const lb = document.querySelector('#testLabel')

  const configureTextarea = () => {
    const txHeight = 36
    if (!tx) return

    if (tx.value === '') {
      tx.setAttribute('style', `height:${txHeight}px; width=365px`)
      lb.setAttribute('style', `height:${txHeight}px; width=365px`)
      lb.classList.remove('addRubbish')
      lb.classList.remove('isBig')
    } else {
      resizeTextarea(tx)
    }
    tx.addEventListener('input', (e) => resizeTextarea(e), false)
  }

  configureTextarea()

  function resizeTextarea(e) {
    const { value, style, scrollHeight } = e.target ? e.target : e

    if (value.length > 40) {
      style.width = `638px`
      style.height = `507px`
      style.paddingRight = '60px'
      style.position = 'absolute'
      lb.classList.add('isBig')
    } else {
      style.width = `365px`
      style.height = `36px`
      style.paddingRight = '35px'
      style.position = 'static'
      lb.classList.remove('isBig')
    }

    scrollHeight > 88
      ? lb.classList.add('addRubbish')
      : lb.classList.remove('addRubbish')

    lb.style.width = style.width
    lb.style.height = style.height
  }

  const txClear = () => {
    tx.style.width = `365px`
    tx.style.height = `36px`
    tx.style.position = 'static'
    lb.style.width = tx.style.width
    lb.style.height = tx.style.height
    lb.classList.remove('addRubbish')
    lb.classList.remove('isBig')
  }
  //------------------------

  const submitButtonRef = useRef(null)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && adaptiveSize) {
      event.preventDefault()
      submitButtonRef.current?.click()
    }
  }

  if (newBase) {
    return (
      <LabelWrap adaptiveSize={adaptiveSize}>
        <Label width={width} styles={labelCss} id={labelId}>
          <InfoWrap styles={infoWrapCss}>
            <LabelText>{label}</LabelText>
            {infoIcon && (
              <ImgInfo src={iconInfo} width="10" height="10" alt="info" />
            )}
          </InfoWrap>
          <TextArea
            className="scroll-shown"
            id={textereaId}
            name={name}
            type={type}
            title={title}
            value={isValue || ''}
            placeholder={placeholder}
            isInvalid={isInvalid}
            required={required}
            disabled={disabled}
            onChange={(selectedOption) => {
              setIsValue(selectedOption.target.value)
              if (handleChange) handleChange(selectedOption)
            }}
            styles={textareaCss}
            onKeyDown={handleKeyDown}
            {...props}
          />
          {adaptiveSize && (
            <>
              <BlackLoupe>
                <Button
                  // ref={submitButtonRef}
                  bgNone
                  icon="loupe"
                  iconOnly
                  type="submit"
                  position="absolute"
                  bottom="0"
                  right="0"
                />
              </BlackLoupe>
              <Rubbish>
                <Button
                  icon="rubbishGray"
                  handleClick={() => {
                    handleClear()
                    txClear()
                  }}
                  iconOnly
                  position="absolute"
                  top="14px"
                  right="15px"
                  zInd="1"
                />
              </Rubbish>

              <WhiteLoupe>
                <button ref={submitButtonRef} type="submit"></button>
                <Button
                  icon="loupeWhite"
                  type="submit"
                  position="absolute"
                  bottom="16px"
                  right="16px"
                  backgroundColor="#1F64EA"
                  borderRadius="4px"
                  iconWidth="50px"
                  heightoff="36px"
                  zInd="1"
                />
              </WhiteLoupe>
            </>
          )}
          {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Label>
      </LabelWrap>
    )
  }

  return (
    <LabelWrap adaptiveSize={adaptiveSize}>
      <Label width={width} styles={labelCss} id={labelId}>
        <InfoWrap styles={infoWrapCss}>
          <LabelText>{label}</LabelText>
          {infoIcon && (
            <ImgInfo src={iconInfo} width="10" height="10" alt="info" />
          )}
        </InfoWrap>
        <TextArea
          className="scroll-shown"
          id={textereaId}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          required={required}
          title={title}
          disabled={disabled}
          {...props}
          onChange={(selectedOption) => {
            if (props?.onChange) props?.onChange(selectedOption)
            if (handleChange) handleChange(selectedOption)
          }}
          styles={textareaCss}
          onKeyDown={handleKeyDown}
        />
        {adaptiveSize && (
          <>
            <BlackLoupe>
              <Button
                // ref={submitButtonRef}
                bgNone
                icon="loupe"
                iconOnly
                type="submit"
                position="absolute"
                bottom="0"
                right="0"
              />
            </BlackLoupe>
            <Rubbish>
              <Button
                icon="rubbishGray"
                handleClick={() => {
                  handleClear()
                  txClear()
                }}
                iconOnly
                position="absolute"
                top="14px"
                right="15px"
                zInd="1"
              />
            </Rubbish>

            <WhiteLoupe>
              <button ref={submitButtonRef} type="submit"></button>
              <Button
                icon="loupeWhite"
                // iconOnly
                type="submit"
                position="absolute"
                bottom="16px"
                right="16px"
                backgroundColor="#1F64EA"
                borderRadius="4px"
                iconWidth="50px"
                heightoff="36px"
                zInd="1"
              />
            </WhiteLoupe>
          </>
        )}
        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Label>
    </LabelWrap>
  )
}
export default NotesArea
