import { useEffect, useState } from 'react'

import styled from 'styled-components'

import iconInfo from 'images/icons/info-black.svg'
import iconUpload from 'images/icons/upload.svg'
import iconCross from 'images/icons/cross.svg'

const Wrap = styled.div`
  position: relative;
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ width }) => (width ? width : '100%')};
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
`

const LabelText = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const Input = styled.input`
  display: none;
`

const ImgInfo = styled.img`
  display: inline-block;
`

const CustomFileInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ clear }) => (clear ? '0' : '16px')};
  background: ${({ clear }) => (clear ? 'transperent' : 'var(--white)')};

  border: ${({ clear }) => (clear ? 'none' : '1px dashed var(--bright)')};
  cursor: pointer;
`

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--primary-1);
  }
`

const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${({ clear }) => (clear ? '-14px' : '3px')};
  right: ${({ clear }) => (clear ? '-62px' : '2px')};
  padding: 12px;
  padding-right: 20px;
  cursor: pointer;

  &:hover {
    bottom: ${({ clear }) => (clear ? '-17px' : '0')};
    right: ${({ clear }) => (clear ? '-64px' : '-2px')};

    img {
      width: 30px;
      height: 30px;
    }
  }
`

const FileInput = ({
  label,
  width,
  infoIcon,
  name,
  handleChange,
  required,
  title,
  disabled,
  text = 'Upload file',
  clear,
  defaultValue = ''
}) => {
  const [fileName, setFileName] = useState(defaultValue)

  useEffect(() => {
    setFileName(defaultValue)
  }, [defaultValue]);

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setFileName(file.name)
      handleChange(file)
    }
  }

  const clearValue = (e) => {
    setFileName('')
  }

  return (
    <Wrap>
      <Label width={width}>
        {label && (
          <InfoWrap>
            <LabelText>{label}</LabelText>
            {infoIcon && (
              <ImgInfo src={iconInfo} width="10" height="10" alt="info" />
            )}
          </InfoWrap>
        )}
        <CustomFileInput clear={clear}>
          <Description>
            {fileName ? (
              fileName
            ) : (
              <>
                <img src={iconUpload} width="20" height="20" alt="info" />
                <span>{text}</span>
              </>
            )}
          </Description>
          <Input
            type="file"
            name={name}
            onChange={handleFileChange}
            required={required}
            title={title}
            disabled={disabled}
          />
        </CustomFileInput>
      </Label>

      {fileName && (
        <CloseIcon onClick={clearValue} clear={clear}>
          <img src={iconCross} width="25" height="25" alt="cross" />
        </CloseIcon>
      )}
    </Wrap>
  )
}

export default FileInput
