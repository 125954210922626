import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { setConstants, setTime, setOptions } from 'redux/info-slice'

import Cookies from 'components/common/utils/cookies'
import Loader from 'components/common/Loader'

import { axiosRefresh } from 'api/auth'
import { constantAll } from 'api/constants'

export const transformForSelect = (data, type) => {
  if (!data) return
  const list = []

  if (type === 'prefix') {
    for (let key in data) {
      list.push({ value: data[key], label: `${key}: +${data[key]}` })
    }
  } else {
    for (let key in data) {
      list.push({ value: key, label: data[key] })
    }
  }

  return list
}

const PrivateRoute = () => {
  const dispatch = useDispatch()

  const { isLogin } = useSelector((state) => state.auth)
  const [isReady, setIsReady] = useState(false)

  const token = Cookies.get(process.env.REACT_APP_TOKEN_KEY)
  const userRole = Cookies.get(process.env.REACT_APP_USER_ROLE_KEY)

  const get_constants = async () => {
    try {
      const data = await constantAll()
      if (data.status === 'success') {
        const res = data.data

        const apiConstants = {
          browsers: transformForSelect(res.browsers),
          devices: transformForSelect(res.devices),
          geos: transformForSelect(res.geos),
          oss: transformForSelect(res.oss),
          integration_types: transformForSelect(res.integration_types),
          verticals: transformForSelect(res.verticals),
          goals: transformForSelect(res.goals),
          trafficSources: transformForSelect(res.traffic),
          languages: transformForSelect(res.languages),
          paid_statuses: transformForSelect(res.paid_statuses),
          currency: transformForSelect(res.currency),
          payment_systems: transformForSelect(res.payment_systems),
          country_code_phone: transformForSelect(
            res.country_code_phone,
            'prefix'
          ),
          manager_roles: transformForSelect(res.manager_roles),
          manager_types: transformForSelect(res.manager_types),
        }

        dispatch(setConstants({ ...res, trafficSources: res.traffic }))
        dispatch(
          setTime({
            time: res.time,
            time_zone: res.partner_settings?.time_zone,
            zone_hours: res.partner_settings?.zone_hours,
          })
        )
        dispatch(setOptions(apiConstants))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    if (isLogin) {
      get_constants()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin])

  useEffect(() => {
    if (!isLogin && token && userRole) {
      axiosRefresh(token, userRole)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isLogin && (!token || !userRole)) {
    return <Navigate to="/login" />
  }

  return isReady ? <Outlet /> : <Loader />
}

export default PrivateRoute
