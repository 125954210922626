const field = {
  search: {
    name: 'search',
    placeholder: 'Search',
    searchIcon: true,
  },
  integrationType: {
    label: 'Integration Type',
    placeholder: 'Choose Type',
    options: [
      { value: 'Adult', label: 'Adult' },
      { value: 'Crypto', label: 'Crypto' },
      { value: 'Gambling', label: 'Gambling' },
      { value: 'Sweepstakes', label: 'Sweepstakes' },
    ],
  },
  currency: {
    placeholder: 'Select Currency',
    options: [
      { value: 'EUR', label: 'EUR' },
      { value: 'USD', label: 'USD' },
      { value: 'UAH', label: 'UAH' },
    ],
  },
  privacyLevel: {
    name: 'privacyLevel',
    placeholder: 'Select Level',
    label: 'Privacy Level',
    readOnly: true,
    data: ['Public', 'Premoderation', 'Private'],
    options: [
      { value: 'Public', label: 'Public' },
      { value: 'Premoderation', label: 'Premoderation' },
      { value: 'Private', label: 'Private' },
    ],
  },
  flow: {
    options: [
      { value: 'DIRECT LINK', label: 'DIRECT LINK' },
      { value: 'Api', label: 'Api' },
    ],
  },
  time_zones: {
    name: 'time_zones',
    placeholder: 'Select Zone',
    label: 'Time Zone',
    readOnly: true,
    data: [
      'GMT-12:00',
      'GMT-11:00',
      'GMT-10:00',
      'GMT-9:00',
      'GMT-8:00',
      'GMT-7:00',
      'GMT-6:00',
      'GMT-5:00',
      'GMT-4:00',
      'GMT-3:00',
      'GMT-2:00',
      'GMT-1:00',
      'GMT+0:00',
      'GMT+1:00',
      'GMT+2:00',
      'GMT+3:00',
      'GMT+4:00',
      'GMT+5:00',
      'GMT+6:00',
      'GMT+7:00',
      'GMT+8:00',
      'GMT+9:00',
      'GMT+10:00',
      'GMT+11:00',
      'GMT+12:00',
      'GMT+13:00',
      'GMT+14:00',
    ],
    options: [
      { value: 'GMT-12:00', label: 'GMT-12:00' },
      { value: 'GMT-11:00', label: 'GMT-11:00' },
      { value: 'GMT-10:00', label: 'GMT-10:00' },
      { value: 'GMT-9:00', label: 'GMT-9:00' },
      { value: 'GMT-8:00', label: 'GMT-8:00' },
      { value: 'GMT-7:00', label: 'GMT-7:00' },
      { value: 'GMT-6:00', label: 'GMT-6:00' },
      { value: 'GMT-5:00', label: 'GMT-5:00' },
      { value: 'GMT-4:00', label: 'GMT-4:00' },
      { value: 'GMT-3:00', label: 'GMT-3:00' },
      { value: 'GMT-2:00', label: 'GMT-2:00' },
      { value: 'GMT-1:00', label: 'GMT-1:00' },
      { value: 'GMT+0:00', label: 'GMT+0:00' },
      { value: 'GMT+1:00', label: 'GMT+1:00' },
      { value: 'GMT+2:00', label: 'GMT+2:00' },
      { value: 'GMT+3:00', label: 'GMT+3:00' },
      { value: 'GMT+4:00', label: 'GMT+4:00' },
      { value: 'GMT+5:00', label: 'GMT+5:00' },
      { value: 'GMT+6:00', label: 'GMT+6:00' },
      { value: 'GMT+7:00', label: 'GMT+7:00' },
      { value: 'GMT+8:00', label: 'GMT+8:00' },
      { value: 'GMT+9:00', label: 'GMT+9:00' },
      { value: 'GMT+10:00', label: 'GMT+10:00' },
      { value: 'GMT+11:00', label: 'GMT+11:00' },
      { value: 'GMT+12:00', label: 'GMT+12:00' },
      { value: 'GMT+13:00', label: 'GMT+13:00' },
      { value: 'GMT+14:00', label: 'GMT+14:00' },
    ],
  },
  period: {
    name: 'period',
    type: 'text',
    placeholder: 'Select Period',
    label: 'Payments Type',
    data: ['No period', 'Daily', 'Weekly', 'Monthly'],
    options: [
      { value: 'No period', label: 'No period' },
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Monthly', label: 'Monthly' },
    ],
  },
  paymentsType: {
    name: 'paymentsType',
    type: 'text',
    placeholder: 'RPS/CPA (Mixed)',
    label: 'Payments Type',
    data: ['CPA', 'CPL', 'RevShare', 'Mixed'],
    options: [
      { value: 'CPA', label: 'CPA' },
      { value: 'RevShare', label: 'RevShare' },
      { value: 'Mixed', label: 'Mixed' },
    ],
  },
  api: {
    browsers: {
      name: 'browsers',
      placeholder: 'Add Browsers',
      label: 'Browsers',
      readOnly: true,
    },
    devices: {
      name: 'devices',
      placeholder: 'Add Devices',
      label: 'Devices',
      readOnly: true,
    },
    geos: {
      name: 'geos',
      placeholder: 'Add Geos',
      label: 'Geos',
      readOnly: true,
      infoIcon: true,
    },
    oss: {
      name: 'oss',
      placeholder: 'Select OS',
      label: 'OS',
      readOnly: true,
    },
    verticals: {
      name: 'verticals',
      placeholder: 'Select Verticals',
      label: 'Verticals',
      readOnly: true,
    },
    goals: {
      name: 'goals',
      placeholder: 'Select Goal',
      label: 'Goal Title',
      readOnly: true,
    },
    advertisers: {
      name: 'advertisers',
      placeholder: 'Select Advertiser',
      label: 'Advertiser',
      readOnly: true,
      infoIcon: true,
    },
    product: {
      name: 'product',
      placeholder: 'Enter Product',
      label: 'Product',
      readOnly: true,
      infoIcon: true,
    },
    trafficSources: {
      name: 'traffic sources',
      type: 'text',
      placeholder: 'Traffic Sources',
      label: 'Traffic Sources',
      infoIcon: true,
      readOnly: true,
    },
    languages: {
      name: 'languages',
      type: 'text',
      placeholder: 'Languages',
      label: 'languages',
      readOnly: true,
    },
    paid_statuses: {
      name: 'paid',
      type: 'text',
      placeholder: 'Paid',
      label: 'paid statuse',
      readOnly: true,
    },
    currency: {
      name: 'currency',
      type: 'text',
      placeholder: 'Currency',
      label: 'Currency',
      readOnly: true,
    },
    payment_systems: {
      name: 'payment_system',
      type: 'text',
      placeholder: 'Payment System',
      label: 'Payment System',
      readOnly: true,
    },
    country_code_phone: {
      name: 'prefix',
      type: 'text',
      placeholder: 'Enter Prefix',
      label: 'Prefix',
      readOnly: true,
    },
  },
}

export default field
