import ConfirmationToggle from './ConfirmationToggle'

import styled from 'styled-components'

const ConfirmationList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 16px;

  & > div {
    flex-basis: calc(25% - 6px);
  }
`

const ToggleList = ({ data, infoIcon, list, setList, reverse, slice }) => {
  const dataKeys = Object.keys(data)

  const toggleInput = (el) => {
    const index = list?.indexOf(el?.toString())

    let newList = []

    if (index === -1) {
      newList = [...list, el]
    } else {
      const listCopy = [...list]
      listCopy?.splice(index, 1)
      newList = listCopy
    }

    setList(newList)
  }

  return (
    <ConfirmationList>
      {dataKeys?.map((el) => (
        <ConfirmationToggle
          key={window?.crypto?.randomUUID()}
          infoIcon={infoIcon}
          reverse={reverse}
          slice={slice}
          text={data[el]}
          callbackFun={() => toggleInput(el)}
          value={list?.includes(el)}
        />
      ))}
    </ConfirmationList>
  )
}

export default ToggleList
