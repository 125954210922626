import { store } from 'redux/store'
import { addNotification } from 'redux/notificationsSlice'

export const getCountryByType = (type, folder = 'countries') => {
  if (!type) return
  if (type === 'ALL') return

  try {
    return require(`images/icons/${folder}/${type}.svg`)
  } catch (error) {
    return
  }
}

export const errorNotify = (data) => {
  if (data?.status === 'error') {
    const errorList = data?.error
    for (let key in errorList) {
      store.dispatch(
        addNotification({
          type: 'error',
          text: `${key} - ${errorList[key]}`,
        })
      )
    }
  }
}

export const notify = (text, type = 'error') => {
  store.dispatch(
    addNotification({
      type,
      text,
    })
  )
}

export const formatTimeData = (data, row) => {
  if (typeof data !== 'string' || !data) return ''
  const indexOfT = data.indexOf('T')
  const indexOfDot = data.indexOf('.')

  return (
    <div>
      {data.slice(2, indexOfT)}
      {row ? ' ' : <br />}
      {data.slice(indexOfT + 1, indexOfDot)}
    </div>
  )
}

export const customSlice = (text, index) => {
  if (!text) return

  if (index) {
    return `${text.slice(0, index)} ${text.slice(index)}`
  }

  return `${text.slice(0, text.length / 2)} ${text.slice(text.length / 2)}`
}

export const sliceMaxLength = (text, index = 10) => {
  if (!text) return

  return `${text.toString().slice(0, index)}${text.length > index ? '...' : ''}`
}

export const transformObjToObjBool = (data) => {
  const list = data

  for (let key in data) {
    list[key] = false
  }

  return list
}

export const handleCoppy = (info) => {
  if (info) {
    navigator.clipboard
      .writeText(info)
      .then(() => notify('Coppied!', 'success'))
      .catch((err) => notify(err))
  }
}

export const openSocial = (social, link) => {
  const type = social?.toLowerCase()
  if (type === 'skype') window.location.href = `skype:${link}?chat`
  if (type === 'telegram') window.location.href = `tg://resolve?domain=${link}`
  if (type === 'email') window.location.href = `mailto:${link}`
  if (type === 'linkedin') window.open(`https://www.linkedin.com/${link}`)
}

//data is from back res, dataDescription is front schema
//defferent key will save into errors obj and shown in console
export const validateSchema = (data, dataDescription, file) => {
  const errors = {}

  for (const key in dataDescription) {
    if (dataDescription.hasOwnProperty(key)) {
      const expectedType = dataDescription[key]
      const actualValue = data[key]
      const isActualValueArray = Array.isArray(actualValue)
      const isArray = expectedType === 'array'

      //if type from back defferent with front schema
      if (
        (isArray && !isActualValueArray) ||
        (!isArray && typeof actualValue !== expectedType) ||
        (!isArray && isActualValueArray)
      ) {
        errors[key] = `must be a (${
          isArray ? 'array' : expectedType
        }) - but exp: (${isActualValueArray ? 'array' : typeof actualValue})`
      }
    }
  }

  const isValid = !Object.keys(errors).length

  if (!isValid) {
    store.dispatch(
      addNotification({
        type: 'error',
        text: 'Data loading error',
      })
    )
    console.log(errors)
    console.log('errors file in -->> ', file)
  }

  return isValid
}

export const toFixedFc = (data) => {
  if (!data) return 0

  return data
  // const numberData = parseFloat(data)

  // if (!isNaN(numberData)) {
  //   return numberData.toFixed(2)
  // } else {
  //   return 0
  // }
}

export const isEmptyObject = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

/* export const callbackFn = (params) => console.log('callbackFn QQ')

export const testFn = (value) => {
  callbackFn()
} */

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
