import Select from 'react-select'
import { Controller } from 'react-hook-form'
import makeAnimated from 'react-select/animated'
import React, { useEffect, useState } from 'react'

import infoIcon from 'images/icons/info-black.svg'

import styled from 'styled-components'

const animatedComponents = makeAnimated()

const Wrap = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : '100%')};
  z-index: ${({ zInd }) => zInd && zInd};

  .css-13cymwt-control {
    border-radius: 0;
  }

  & .css-1u9des2-indicatorSeparator {
    display: none;
  }

  & .css-1jqq78o-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${({ blue }) => (blue ? 'var(--blue)' : '')};
  }

  & .css-y5o868-control {
    /* position: ${({ isMulti }) => isMulti && 'relative'}; */
    padding-right: ${({ isMulti }) => isMulti && '64px'};
  }

  & .css-1hb7zxy-IndicatorsContainer {
    cursor: pointer;
    position: ${({ isMulti }) => isMulti && 'absolute'};
    top: ${({ isMulti }) => isMulti && '0'};
    right: ${({ isMulti }) => isMulti && '0'};
  }

  & .css-y5o868-control {
    /* ::-webkit-scrollbar {
      width: 6px;
      height: 0;
      background-color: var(--white);
    } */

    ::-webkit-scrollbar-thumb {
      background: var(--bright);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--blue);
    }

    ::-webkit-scrollbar-thumb:active {
      background: #25477a;
    }
  }
`

const Label = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
`

const LabelText = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const ImgInfo = styled.img`
  display: inline-block;
`

const ErrorMessage = styled.p`
  position: absolute;
  bottom: -17px;
  left: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--red);
`

const SelectInput = ({
  name,
  control,
  register,
  errors,
  options,
  required,
  width,
  label,
  errorMessage = 'Invalid Value',
  isMulti,
  isClearable = true,
  placeholder,
  defaultValue,
  reset,
  blue,
  handleChange,
  controlled,
  value,
  setValue,
  zInd,
  disabledOptions,
}) => {
  const selectRef = React.createRef()
  const [isValue, setIsValue] = useState(false)
  const [isAll, setIsAll] = useState(false)

  useEffect(() => {
    if (reset) selectRef.current.clearValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  const checkOptions = (options) => {
    if (options?.length) {
      setIsValue(true)
      setIsAll(options[0]?.value === 'ALL')
    }

    if (!options?.length) setIsValue(false)
  }

  if (controlled)
    return (
      <Wrap width={width} blue={blue} isMulti={isMulti} zInd={zInd}>
        {label && (
          <Label>
            <InfoWrap>
              <LabelText>{label}</LabelText>
              {required && (
                <ImgInfo src={infoIcon} width="10" height="10" alt="info" />
              )}
            </InfoWrap>
          </Label>
        )}
        <Select
          ref={selectRef}
          components={animatedComponents}
          options={options}
          isMulti={isMulti}
          closeMenuOnSelect={isMulti ? false : true}
          isClearable={isClearable}
          placeholder={placeholder}
          value={value}
          isOptionDisabled={({ value }) => {
            if (isValue) return isAll ? value !== 'ALL' : value === 'ALL'
          }}
          onChange={(selectedOption) => {
            checkOptions(selectedOption)
            if (handleChange) handleChange(selectedOption)
          }}
        />
      </Wrap>
    )

  return (
    <Wrap width={width} blue={blue} isMulti={isMulti} zInd={zInd}>
      {label && (
        <Label>
          <InfoWrap>
            <LabelText>{label}</LabelText>
            {required && (
              <ImgInfo src={infoIcon} width="10" height="10" alt="info" />
            )}
          </InfoWrap>
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            ref={selectRef}
            components={animatedComponents}
            options={options}
            isMulti={isMulti}
            closeMenuOnSelect={isMulti ? false : true}
            isClearable={isClearable}
            placeholder={placeholder}
            isOptionDisabled={({ value }) => {
              if (isValue) return isAll ? value !== 'ALL' : value === 'ALL'
              if (disabledOptions) return disabledOptions.includes(value)
            }}
            props={{
              ...register(name, { required: required }),
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: errors[name] ? 'red' : 'rgb(206, 210, 216)',
                borderRadius: '0',
                boxShadow: 'none',
                minHeight: '36px',
                height: !isMulti && '36px',
                maxHeight: '60px',
                overflowY: isMulti && 'scroll',
                '&:hover': {
                  borderColor: 'none',
                },
              }),
            }}
            onChange={(selectedOption) => {
              checkOptions(selectedOption)
              if (handleChange) handleChange(selectedOption)

              field.onChange(selectedOption)
            }}
            onInputChange={(inputValue) => {
              if (setValue && inputValue?.length > 1) setValue(inputValue)
            }}
          />
        )}
      />

      {errors[name] && (
        <ErrorMessage>{errors[name].message || errorMessage}</ErrorMessage>
      )}
    </Wrap>
  )
}

export default SelectInput
