import Cookies from 'components/common/utils/cookies'
import { instance } from './interceptors'
import { store } from 'redux/store'
import { refresh } from 'redux/auth/auth-slice'
import { clearNotifications } from 'redux/notificationsSlice'

const base = 'api/auth/v1/'

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY
const USER_ROLE_KEY = process.env.REACT_APP_USER_ROLE_KEY
const USER_UUID_KEY = process.env.REACT_APP_USER_UUID_KEY
const USER_TYPE_KEY = process.env.REACT_APP_USER_TYPE_KEY
const USER_EMAIL_KEY = process.env.REACT_APP_USER_EMAIL_KEY

export const axiosLogIn = async (data) => {
  try {
    const response = await instance.post(`${base}login`, data)
    const token = response.data.token
    const user_role = response.data.user_role
    const uuid = response.data.uuid
    const type = response.data.type
    const email = response.data.email

    store.dispatch(clearNotifications())
    Cookies.set(TOKEN_KEY, token)
    Cookies.set(USER_ROLE_KEY, user_role)
    Cookies.set(USER_UUID_KEY, uuid)
    Cookies.set(USER_TYPE_KEY, type)
    Cookies.set(USER_EMAIL_KEY, email)
    instance.defaults.headers['PXL-AUTHORIZATION'] = `${token}`
    return response
  } catch (error) {
    throw error
  }
}

export const axiosRefresh = (token, user_role) => {
  instance.defaults.headers['PXL-AUTHORIZATION'] = `${token}`

  if (token && user_role) {
    const type = Cookies.get(process.env.REACT_APP_USER_TYPE_KEY)
    const uuid = Cookies.get(USER_UUID_KEY)
    const email = Cookies.get(USER_EMAIL_KEY)

    store.dispatch(refresh({ user_role, type, uuid, email }))
  }
}

// export const axiosRefresh = async (refresh) => {
//   try {
//     const response = await instance.post(`${base}refresh`, {
//       refresh,
//     })
//     const { access } = response.data

//     Cookies.set('accessToken', access)
//     instance.defaults.headers.common['Authorization'] = `Bearer ${access}`
//     // instance.defaults.headers.Authorization = `Bearer ${access}`
//     // console.log(instance.defaults.headers)
//     return { access }
//   } catch (error) {
//     Cookies.remove('refreshToken')
//     throw error
//   }
// }

export const axiosLogout = async () => {
  try {
    const response = await instance.get(`${base}logout`)
    instance.defaults.headers['PXL-AUTHORIZATION'] = null

    Cookies.removeAll()

    return response.data
  } catch (error) {
    Cookies.removeAll()

    throw error
  }
}

export const defaultRequest = async (
  url,
  type = 'get',
  body,
  contentType = 'application/json'
) => {
  try {
    const { data } = await instance[type](url, body, {
      headers: {
        'Content-Type': contentType,
      },
    })
    return data
  } catch (error) {
    console.log(error.message)
    throw error
  }
}

export default instance

// class Client {
//     #host = 'demo.host.com'
//     #prefix = '/api/v2'
//     #listURL = '/offers'
//     #detailsURL = '/offers/<id>'

//     constructor(cookies) {
//         this.cookies = cookies
//         this.headerToken = headers.token;
//     }

//     #requestHeaders(){
//         return {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Authorization': this.headerToken,
//         }
//     }

//     async #request(endpoint, payload={}) {
//         let options = {headers: this.#requestHeaders}
//         if (endpoint === this.#listURL) {
//             options['method'] = 'get'
//         }else{
//             options['method'] = 'post'
//             options['body'] = JSON.stringify(payload),
//         }
//         let url = this.#host + this.#prefix + endpoint;

//         const response = await fetch(url, options);
//         if (!response.ok) {
//             throw new Error(`Response status: ${response.status}`);
//         }
//         return await response.json();
//     }

//     getOffers(){
//         return this.#request(this.#listURL)
//     }
//     getOffer(id){
//         let endpoint = this.#detailsURL.replace('<id>', id)
//         return this.#request(endpoint)
//     }
// }
