/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import infoIcon from 'images/icons/info-black.svg'
import useOutsideClick from 'components/hooks/useOutsideClick'

const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  align-items: center;

  ${({ styles }) => styles}
`

const LabelText = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
`

const ImgInfo = styled.img`
  display: inline-block;
`

const InputInnerWrapper = styled.div`
  position: relative;

  display: flex;
  flex-wrap: wrap;

  width: 100%;
  max-height: 55px;
  border: 1px solid #ced2d8;

  overflow: hidden;
  overflow-y: scroll;

  background: var(--white);

  ${(props) =>
    props.isInvalid
      ? `
      border-color: #BB4126;
      box-shadow: 0 0 0 1px #BB4126;
    `
      : `
      border-color: #CED2D8;
      box-shadow: none;
    `}

  ${({ styles }) => styles}
`

const SelectedImg = styled.img`
  width: ${({ isSelectedOption }) => isSelectedOption && '34px'};

  ${({ styles }) => styles}
`

const Input = styled.input`
  width: ${({ isImg }) => (isImg ? 'calc(100% - 64px)' : 'calc(100% - 30px)')};

  padding: 8px 12px 8px 8px;
  background-color: #fff;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  ${({ styles }) => styles}
`

const Cross = styled.svg`
  position: absolute;
  top: 9px;
  right: 38px;
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #cccccc;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  height: auto;
  cursor: pointer;

  &:hover {
    fill: #999999;
  }

  ${({ styles }) => styles}
`

const DropdownWrap = styled.div`
  width: 30px;
  height: 34px;
  display: flex;
  justify-content: center;

  /* background-color: #EDEDEA; */

  ${({ styles }) => styles}
`

const Dropdown = styled.svg`
  display: inline-block;
  fill: ${({ isShowOptions }) => (isShowOptions ? '#999999' : '#CCCCCC')};
  transform: ${({ isShowOptions }) =>
    isShowOptions ? 'rotate(180deg)' : 'none'};
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  height: auto;
  cursor: pointer;

  &:hover {
    fill: #999999;
  }
`

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  z-index: 1;

  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ced2d8;
  background-color: #fff;

  ${({ styles }) => styles}
`

const OptionsItem = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 8px;
  width: 100%;
  background-color: #fff;

  &:hover {
    background-color: #deebff;
  }

  ${({ styles }) => styles}
`

const ItemImg = styled.img`
  width: 34px;
  margin-right: 15px;

  ${({ styles }) => styles}
`

const ErrorMessage = styled.p`
  position: absolute;
  bottom: -17px;
  left: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--red);

  ${({ styles }) => styles}
`

const Select = ({
  name = '',
  placeholder,
  options = [],
  label,

  error,
  formData,
  returnValue,
  defaultValue,

  getOption,
  getValue,

  isClearable = false,
  required = false,
  isReset,
  errorMessage = 'Invalid Value',

  containerStyles,
  itemImgStyles,
  inputStyles,
  dropdownWrapStyles,
  listStyles,
  itemStyles,
  selectedImgStyles,
}) => {
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [filter, setFilter] = useState('')

  const currentValue =
    formData && formData[name] ? formData[name] : defaultValue || '{}'
  // const [currentOption = {}] = options.filter(({ value }) => currentValue.includes(value));
  const currentOption =
    options.find((option) => option.value === currentValue) || {}

  const [selectedOption, setSelectedOption] = useState(currentOption)
  const isEmptySelectedOption = JSON.stringify(selectedOption) === '{}'

  const isImg = !!(options.length && typeof options[0].label === 'object')

  useEffect(() => {
    if (getValue && filter && filter.length > 1) getValue(filter)
  }, [filter])

  useEffect(() => {
    if (getOption) getOption(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (isReset) setSelectedOption(currentOption)
  }, [isReset])

  useEffect(() => {
    if (returnValue) {
      returnValue(selectedOption.value)
    }
  }, [selectedOption])

  const ref = useRef(null)

  const open = () => setIsShowOptions(true)
  const close = () => setIsShowOptions(false)
  const toggle = () => setIsShowOptions(!isShowOptions)

  const changeWrapRef = useRef(null)
  useOutsideClick(changeWrapRef, close)

  const generateCSS = (elementStyles) => {
    if (!elementStyles) return

    const cssText = Object.entries(elementStyles)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ')

    return cssText
  }

  const containerCSS = generateCSS(containerStyles)
  const itemImgCSS = generateCSS(itemImgStyles)
  const inputCSS = generateCSS(inputStyles)
  const dropdownWrapCss = generateCSS(dropdownWrapStyles)
  const listCSS = generateCSS(listStyles)
  const itemCSS = generateCSS(itemStyles)
  const selectedImgCSS = generateCSS(selectedImgStyles)

  const changeFilter = (e) => {
    setFilter(e.currentTarget.value)
  }

  const onSelectOption = ({ value, label }) => {
    if (isImg) {
      const { name, img } = label
      setSelectedOption({ value, label: { name, img } })
    } else {
      setSelectedOption({ value, label })
    }
    close()
  }

  const getVisibleOptions = () => {
    if (isImg) {
      return options?.filter((option) =>
        option.label.name
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase())
      )
    } else {
      return options?.filter(
        (option) =>
          option.label
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
          option.value.toString().toLowerCase().includes(filter.toLowerCase())
      )
    }
  }

  const isInvalid = required && isEmptySelectedOption && error[name]

  const visibleOptions = getVisibleOptions()

  return (
    <SelectContainer
      data-testid={`${name}-container`}
      className="unclosed"
      ref={changeWrapRef}
      onFocus={() => open()}
      styles={containerCSS}
    >
      {label && (
        <InfoWrap>
          <LabelText data-testid={`${name}-label`}>{label}</LabelText>
          {required && (
            <ImgInfo src={infoIcon} width="10" height="10" alt="info" />
          )}
        </InfoWrap>
      )}
      <InputInnerWrapper
        data-testid={`${name}-input_wrapper`}
        isInvalid={isInvalid}
      >
        {isImg && !isEmptySelectedOption && !isShowOptions && (
          <SelectedImg
            data-testid={`${name}-selected_img`}
            isSelectedOption={!!selectedOption}
            src={selectedOption.label?.img}
            alt={selectedOption.label?.name}
            styles={selectedImgCSS}
          />
        )}
        <Input
          data-testid={`${name}-input`}
          isSelectedValue={selectedOption}
          type="text"
          placeholder={placeholder}
          inputEl={ref}
          onChange={changeFilter}
          isImg={isImg && !isEmptySelectedOption && !isShowOptions}
          value={
            isShowOptions
              ? filter
              : (isImg ? selectedOption.label?.name : selectedOption.label) ||
                (isEmptySelectedOption ? '' : selectedOption.label)
          }
          styles={inputCSS}
        />
        {isClearable && !isEmptySelectedOption && (
          <Cross
            data-testid={`${name}-cross`}
            viewBox="0 0 24 24"
            onClick={() => setSelectedOption({})}
          >
            <path d="M7.1 18.3C6.7134 18.6866 6.0866 18.6866 5.7 18.3C5.3134 17.9134 5.3134 17.2866 5.7 16.9L10.6 12L5.7 7.1C5.3134 6.7134 5.3134 6.0866 5.7 5.7C6.0866 5.3134 6.7134 5.3134 7.1 5.7L12 10.6L16.9 5.7C17.2866 5.3134 17.9134 5.3134 18.3 5.7C18.6866 6.0866 18.6866 6.7134 18.3 7.1L13.4 12L18.3 16.9C18.6866 17.2866 18.6866 17.9134 18.3 18.3C17.9134 18.6866 17.2866 18.6866 16.9 18.3L12 13.4L7.1 18.3Z"></path>
          </Cross>
        )}
        <DropdownWrap styles={dropdownWrapCss}>
          <Dropdown
            data-testid={`${name}-drop_down`}
            onClick={() => toggle()}
            isShowOptions={isShowOptions}
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </Dropdown>
        </DropdownWrap>
      </InputInnerWrapper>

      <OptionsList
        data-testid={`${name}-list`}
        isShow={isShowOptions}
        styles={listCSS}
      >
        {visibleOptions?.map(({ value, label }) => (
          <OptionsItem
            key={value}
            data-value={value}
            data-testid={`${name}-option`}
            styles={itemCSS}
            onClick={() => {
              onSelectOption({ value, label })
              setFilter('')
            }}
          >
            {isImg ? (
              <>
                <ItemImg src={label.img} alt={label.name} styles={itemImgCSS} />
                <p>{label.name}</p>
              </>
            ) : (
              label
            )}
          </OptionsItem>
        ))}
      </OptionsList>

      <input
        name={name}
        value={selectedOption.value || ''}
        readOnly
        data-testid={`${name}-input_hidden`}
        data-required={required}
        style={{
          display: 'none',
        }}
      />

      {/* <select
        id="default-select"
        style={{
          display: 'none',
        }}
        name={name}
        value={selectedOption.value ? selectedOption.value : ''}
      >
        {visibleOptions?.map(({ value, label }) => (
          <option key={value} value={value}>
            {isImg ? label.name : label}
          </option>
        ))}
      </select> */}
      {isInvalid && (
        <ErrorMessage data-testid={`${name}-error_message`}>
          {errorMessage}
        </ErrorMessage>
      )}
    </SelectContainer>
  )
}

export default Select
