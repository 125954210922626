import { useEffect, useState } from 'react'

import styled from 'styled-components'

import iconLoupe from 'images/icons/loupe.svg'
import cross from 'images/icons/cross.svg'
import iconInfo from 'images/icons/info-black.svg'
import iconEye from 'images/icons/eye.svg'

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ width }) => (width ? width : '100%')};
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
`

const LabelText = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const Input = styled.input`
  width: 100%;
  padding: 8px;
  padding-right: 12px;
  padding-right: ${({ paddingRight }) => paddingRight && '27px'};

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background: ${({ disabled }) =>
    disabled ? 'var(--secondary-1)' : 'var(--white)'};
  border: 1px solid var(--bright);

  ${(props) =>
    props.isInvalid
      ? `
      border-color: #BB4126;
      box-shadow: 0 0 0 1px #BB4126;
    `
      : `
      border-color: #CED2D8;
      box-shadow: none;
    `}

  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor && placeholderColor};
  }
`

const ImgWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px 8px;
  cursor: ${({ isHandler }) => isHandler && 'pointer'};
`

const ImgInfo = styled.img`
  display: inline-block;
`

const ErrorMessage = styled.p`
  position: absolute;
  bottom: -17px;
  left: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--red);
`

const BaseInput = ({
  name,
  label,
  type,
  placeholder,
  title,

  error,
  errorMessage = 'Invalid Value',

  step = '0.001',
  mobile = false,
  formData,

  handleChange,
  onSearch,
  handleClear,
  onKeyPress,

  isReset,
  newBase,
  defaultValue,
  required,
  disabled,
  latinOnly,
  infoIcon,
  searchIcon,

  width,
  placeholderColor,

  props,
}) => {
  const currentValue =
    formData && formData[name] ? formData[name] : defaultValue || ''
  const [isValue, setIsValue] = useState(currentValue)

  useEffect(() => {
    setIsValue(defaultValue)
  }, [defaultValue])

  const onCross = () => {
    setIsValue('')
    if (handleClear) handleClear()
  }

  useEffect(() => {
    if (isReset) setIsValue(currentValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset])

  const handleKeyPress = (e) => {
    const charCode = e.charCode || e.keyCode
    const char = String.fromCharCode(charCode)
    const latinRegex = /^[a-zA-Z0-9\s$*@!]*$/

    if (!latinRegex.test(char)) {
      e.preventDefault()
    }

    if (onKeyPress) onKeyPress(e)
  }

  const isInvalid = required && newBase && error && error[name] && !isValue

  const [inputType, setInputType] = useState(type)
  const changeType = () => {
    if (inputType === 'password') {
      setInputType('name')
    } else {
      setInputType('password')
    }
  }

  if (newBase) {
    return (
      <Label width={width}>
        {label && (
          <InfoWrap>
            <LabelText>{label}</LabelText>
            {required && (
              <ImgInfo src={iconInfo} width="10" height="10" alt="info" />
            )}
          </InfoWrap>
        )}
        <Input
          name={name}
          type={inputType}
          step={step}
          title={title}
          value={isValue || ''}
          data-testid={`${name}-input`}
          disabled={disabled}
          isInvalid={isInvalid}
          data-required={required}
          // required={required}
          placeholder={placeholder}
          paddingRight={searchIcon}
          placeholderColor={placeholderColor}
          onKeyPress={latinOnly ? handleKeyPress : onKeyPress}
          onChange={(selectedOption) => {
            setIsValue(selectedOption.target.value)
            if (handleChange) handleChange(selectedOption)
          }}
          {...props}
        />
        {mobile && (
          <>
            {width === '100%' ? (
              <ImgWrap
                data-testid="cross-btn"
                onClick={onCross}
                isHandler={onSearch}
              >
                <img src={cross} width="18" height="18" alt="cross" />
              </ImgWrap>
            ) : (
              <ImgWrap onClick={onSearch} isHandler={onSearch}>
                <img src={iconLoupe} width="18" height="18" alt="loupe" />
              </ImgWrap>
            )}
          </>
        )}

        {type === 'password' && (
          <ImgWrap onClick={changeType} isHandler={true}>
            <img src={iconEye} width="18" height="18" alt="eye" />
          </ImgWrap>
        )}

        {searchIcon && !mobile && (
          <>
            {isValue ? (
              <ImgWrap
                data-testid="cross-btn"
                onClick={onCross}
                isHandler={onSearch}
              >
                <img src={cross} width="18" height="18" alt="cross" />
              </ImgWrap>
            ) : (
              <ImgWrap isHandler={onSearch}>
                <button type="submit" style={{ background: 'none' }}>
                  <img src={iconLoupe} width="18" height="18" alt="loupe" />
                </button>
              </ImgWrap>
            )}
          </>
        )}

        {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Label>
    )
  }

  return (
    <Label width={width}>
      {label && (
        <InfoWrap>
          <LabelText>{label}</LabelText>
          {infoIcon && (
            <ImgInfo src={iconInfo} width="10" height="10" alt="info" />
          )}
        </InfoWrap>
      )}
      <Input
        type={type}
        step={step}
        name={name}
        // value={isValue}
        data-testid={`${name}-input`}
        placeholder={placeholder}
        required={required}
        title={title}
        disabled={disabled}
        paddingRight={searchIcon}
        placeholderColor={placeholderColor}
        onKeyPress={latinOnly ? handleKeyPress : onKeyPress}
        {...props}
        onChange={(selectedOption) => {
          setIsValue(selectedOption.target.value)
          props?.onChange
            ? props?.onChange(selectedOption)
            : handleChange(selectedOption)
        }}
      />
      {mobile && (
        <>
          {width === '100%' ? (
            <ImgWrap
              data-testid="cross-btn"
              onClick={onCross}
              isHandler={onSearch}
            >
              <img src={cross} width="18" height="18" alt="cross" />
            </ImgWrap>
          ) : (
            <ImgWrap onClick={onSearch} isHandler={onSearch}>
              <img src={iconLoupe} width="18" height="18" alt="loupe" />
            </ImgWrap>
          )}
        </>
      )}

      {searchIcon && !mobile && (
        <>
          {isValue ? (
            <ImgWrap
              data-testid="cross-btn"
              onClick={onCross}
              isHandler={onSearch}
            >
              <img src={cross} width="18" height="18" alt="cross" />
            </ImgWrap>
          ) : (
            <ImgWrap onClick={onSearch} isHandler={onSearch}>
              <img src={iconLoupe} width="18" height="18" alt="loupe" />
            </ImgWrap>
          )}
        </>
      )}

      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Label>
  )
}

export default BaseInput
