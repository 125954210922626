// export const Cookie = {
//   set: function (name, value, options) {
//     let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
//       value
//     )}`

//     if (options) {
//       if (options.expires) {
//         cookieString += `;expires=${options.expires.toUTCString()}`
//       }
//       if (options.path) {
//         cookieString += `;path=${options.path}`
//       }
//       if (options.domain) {
//         cookieString += `;domain=${options.domain}`
//       }
//       if (options.secure) {
//         cookieString += ';secure'
//       }
//     }

//     document.cookie = cookieString
//   },

//   get: function (name) {
//     const cookies = document.cookie.split(';')
//     for (const cookie of cookies) {
//       const [cookieName, cookieValue] = cookie
//         .split('=')
//         .map((part) => part.trim())
//       if (cookieName === name) {
//         return decodeURIComponent(cookieValue)
//       }
//     }
//     return null
//   },

//   remove: function (name, options) {
//     this.set(name, '', { expires: new Date(0), ...options })
//   },

//   removeAll: function () {
//     document.cookie.split(';').forEach((cookie) => {
//       const [name] = cookie.split('=').map((part) => part.trim())
//       this.remove(name)
//     })
//   },
// }

// Cookies -->> 2
// const Cookies = {
//   set: function (name, value, options = {}) {
//     let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
//       value
//     )}`

//     if (options.expires) {
//       cookieString += `;expires=${options.expires.toUTCString()}`
//     }
//     if (options.path) {
//       cookieString += `;path=${options.path}`
//     }
//     if (options.domain) {
//       cookieString += `;domain=${options.domain}`
//     }
//     if (options.secure) {
//       cookieString += ';secure'
//     }

//     document.cookie = cookieString
//   },

//   get: function (name) {
//     const cookies = document.cookie.split(';')
//     for (const cookie of cookies) {
//       const [cookieName, cookieValue] = cookie
//         .split('=')
//         .map((part) => part.trim())
//       if (cookieName === name) {
//         return decodeURIComponent(cookieValue)
//       }
//     }
//     return null
//   },

//   remove: function (name, options = {}) {
//     // Use the same options as when setting the cookie to ensure it gets removed properly
//     this.set(name, '', {
//       expires: new Date(0),
//       path: options.path,
//       domain: options.domain,
//       secure: options.secure,
//     })
//   },

//   removeAll: function (options = {}) {
//     document.cookie.split(';').forEach((cookie) => {
//       const [name] = cookie.split('=').map((part) => part.trim())
//       this.remove(name, options)
//     })

//     // if (document && document.window) document.window.location = '/login'
//   },
// }

// Cookies -->> 3
const Cookies = {
  set: function (name, value, options = {}) {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
      value
    )}`

    if (options.expires) {
      const expires =
        options.expires instanceof Date
          ? options.expires.toUTCString()
          : new Date(options.expires).toUTCString()
      cookieString += `;expires=${expires}`
    }
    if (options.path) {
      cookieString += `;path=${options.path}`
    }
    if (options.domain) {
      cookieString += `;domain=${options.domain}`
    }
    if (options.secure) {
      cookieString += ';secure'
    }

    document.cookie = cookieString
  },

  get: function (name) {
    const cookies = document.cookie
      .split('; ')
      .map((cookie) => cookie.split('='))
    for (const [cookieName, cookieValue] of cookies) {
      if (cookieName === encodeURIComponent(name)) {
        return decodeURIComponent(cookieValue)
      }
    }
    return null
  },

  remove: function (name, options = {}) {
    this.set(name, '', {
      expires: new Date(0),
      path: options.path,
      domain: options.domain,
      secure: options.secure,
    })
  },

  removeAll: function (options = {}) {
    document.cookie.split('; ').forEach((cookie) => {
      const [name] = cookie.split('=').map((part) => part.trim())
      this.remove(decodeURIComponent(name), options)
    })
  },
}

export default Cookies
